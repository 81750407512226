<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-white">
      Öğretmenevi Yönetim Sistemi (Büro Modül)  © {{ new Date().getFullYear() }},
      3K-CRM Mimarisi üzerine dizayn edilmiştir
      <span class="d-none d-sm-inline-block">, Tüm Hakları Saklıdır</span>
    </span>

    <span class="float-md-right d-none d-md-block text-white">
        <b-link
            class="ml-25 text-white"
            href="https://arvas.com.tr"
            target="_blank"
        >Arvas Bilgi Teknolojileri</b-link> / Version : 1.0-Releases
      <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
